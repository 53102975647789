import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button, Modal, Select, Space } from 'antd'

const NULL_OPTION_VALUE = 'NULL_VALUE'

const FilterLabel = styled.div`
    margin-top: 5px;
`

const FilterModal = ({ filterConfig, modalVisible, onSubmit, onCancel }) => {
  const [values, setValues] = useState({})

  const resetValues = useCallback(() => {
    setValues({})
    onSubmit({})
  }, [onSubmit])

  const generateFilterList = useCallback(() => {
    onSubmit(values)
  }, [onSubmit, values])

  const onListChange = useCallback((key, value) => {
    setValues(current => {
      if(value === NULL_OPTION_VALUE) {
        const newFilters = {...current}
        delete newFilters[key]
        return newFilters
      }else{
        return { ...current, [key]: value }
      }
    })
  }, [])

  return(
    <>
      <Modal
        title={'Filtry'}
        visible={modalVisible}
        onOk={generateFilterList}
        onCancel={onCancel}
        footer={[
          <Button
            key={'reset'}
            onClick={resetValues}
          >
                        Reset
          </Button>,
          <Button
            type={'primary'}
            key={'submit'}
            onClick={generateFilterList}
          >
                        Zatwierdź
          </Button>,
        ]}
      >
        {
          filterConfig.map(filter => (
            <Space
              key={filter.key}
              direction={'vertical'}
              style={{ width: '100%' }}
            >
              <FilterLabel>{filter.name}</FilterLabel>
              <Select
                style={{ width: '100%' }}
                defaultValue={
                  filter.values[filter.defaultIdx].value
                    ?
                    filter.values[filter.defaultIdx].value
                    :
                    NULL_OPTION_VALUE
                }
                onChange={v => onListChange(filter.key, v)}
                value={values[filter.key] ? values[filter.key] : NULL_OPTION_VALUE}
              >
                {
                  filter.values.map(option => (
                    <Select.Option
                      key={option.text}
                      value={option.value ? option.value : NULL_OPTION_VALUE}
                    >
                      {option.text}
                    </Select.Option>
                  ))
                }
              </Select>
            </Space>
          ))
        }
      </Modal>
    </>
  )
}

FilterModal.propTypes = {
  filterConfig: PropTypes.array.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default FilterModal
