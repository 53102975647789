import React from 'react'
import styled from 'styled-components'
import rankIcon from '../../assets/rank-icon.png'
import trnIcon from '../../assets/trn-icon.png'
import padIcon from '../../assets/pad-icon.png'
import divisionIcon from '../../assets/division-icon.png'
import {analyzeRankDivisions, analyzeTeamRanks} from '../../firebase/firestore/ranks.firestore'
import {EntryFormPanel} from "./panels/EntryFormPanel";
import {RecordCounterPanel} from "./panels/RecordCounterPanel";
import {CurrentMatchPanel} from "./panels/CurrentMatchPanel";
import {StatsModalPanel} from "./panels/StatsModalPanel";
import {analyzePlayerPlatforms, analyzeTrackerPlatforms} from '../../firebase/firestore/platforms.firebase'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const DashboardStartView = () => (
  <Container>
    <EntryFormPanel />
    <RecordCounterPanel
      title={'Zapisy'}
      description={'oczekuje na dołączenie'}
      collectionName={'entries'}
      goToPath={'/dashboard/entries'}
    />
    <RecordCounterPanel
      title={'Turniej'}
      description={'drużyn w turnieju'}
      collectionName={'teams'}
      goToPath={'/dashboard/teams'}
    />
    <StatsModalPanel
      title={'Rangi graczy'}
      icon={rankIcon}
      dataAnalyzer={analyzeTeamRanks}
    />
    <StatsModalPanel
      title={'Dywizje'}
      icon={divisionIcon}
      dataAnalyzer={analyzeRankDivisions}
    />
    <StatsModalPanel
      title={'Profile TRN'}
      icon={trnIcon}
      dataAnalyzer={analyzeTrackerPlatforms}
    />
    <StatsModalPanel
      title={'Platformy graczy'}
      icon={padIcon}
      dataAnalyzer={analyzePlayerPlatforms}
    />
    <CurrentMatchPanel />
  </Container>
)

export default DashboardStartView
