import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import {Modal, Button, Input, message} from 'antd'
import {BOX_STATE, openThisBox} from '../../firebase/firestore/mysteryBox.firestore'

const BigText = styled.div`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
`

const SmallText = styled.div`
  font-size: 1rem;
  text-align: center;
`

const BoxOpeningModal = ({ boxData, onHide }) => {
  const [receiverValue, setReceiverValue] = useState('')

  const onOpenClick = useCallback(() => {
    openThisBox(boxData.docId, receiverValue)
      .then(() => message.success('Box został otwarty!'))
  }, [receiverValue, boxData])

  return(
    <Modal
      title={'Box numer ' + boxData.box}
      visible={true}
      onOk={onHide}
      onCancel={onHide}
      footer={null}
      centered
    >
      {
        boxData.state === BOX_STATE.OPEN
        ?
          <>
            <SmallText>Wygrywasz:</SmallText>
            <BigText>{boxData.value}</BigText>
            <SmallText>Zwycięzca: {boxData.receiver}</SmallText>
          </>
          :
          <>
            <Input
              value={receiverValue}
              onChange={e => setReceiverValue(e.target.value)}
              placeholder={'Nick zwycięzcy nagrody (min. 3 znaki)'}
            />
            <Button
              type={'primary'}
              disabled={receiverValue.length < 3}
              onClick={onOpenClick}
              block
            >
              Otwórz
            </Button>
          </>
      }
    </Modal>
  )
}

export default BoxOpeningModal
