import React, {useEffect, useState} from "react";

import {Button, Modal} from "antd";
import {BigText, FooterButtons, PanelBlock, ChartContainer, Title} from "./CommonElements";

export const StatsModalPanel = ({ dataAnalyzer, icon, title }) => {
    const [visible, setVisible] = useState(false)
    const [data, setData] = useState(null)
    useEffect(() => {
        setData(null)
        if(visible) {
            setTimeout(() => {
                dataAnalyzer().then(stats => setData(stats))
            }, 500)
        }
    }, [visible])
    return(
        <PanelBlock>
            <Title>{title}</Title>
            <BigText><img src={icon} alt={'rl-rank'} style={{ marginTop: '-20px' }} /></BigText>
            <FooterButtons>
                <Button size={'small'} type={'primary'} onClick={() => setVisible(true)}>
                    Pokaż diagram
                </Button>
            </FooterButtons>
            <Modal
                title={title}
                visible={visible}
                style={{ width: '90vw' }}
                onCancel={() => setVisible(false)}
                footer={false}
            >
                {
                    data
                        ?
                        <ChartContainer
                            style={{
                                fontFamily: '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                                fontSize: '3px',
                                fontWeight: 'bold'
                            }}
                            data={data}
                            radius={50}
                            lineWidth={70}
                            animate
                            label={({ dataEntry }) => `${dataEntry.title} (${dataEntry.value})`}
                            labelPosition={65}
                            labelStyle={{
                                fill: '#000',
                                opacity: 0.7,
                                pointerEvents: 'none',
                            }}
                        />
                        :
                        <i>Pobieranie danych...</i>
                }
            </Modal>
        </PanelBlock>
    )
}
