const rocketLeagueRanks = {
  bronze: 'Bronze',
  silver: 'Silver',
  gold: 'Gold',
  platinum: 'Platinum',
  diamond: 'Diamond',
  champion: 'Champion',
  grandChampion: 'Grand Champion',
  supersonicLegend: 'Supersonic Legend'
}

export default rocketLeagueRanks
