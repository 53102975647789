import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const useQuery = () => {
  const location = useLocation()
  const [data, setData] = useState({})

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const obj = {}
    for(const pair of query.entries()) obj[pair[0]] = pair[1]
    setData(obj)
  }, [location])

  return data
}

export default useQuery
