import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { DEFAULT_UNAUTH_URL } from './config/routes'
import firebase from './firebase/firebase'
import LoaderLayout from './layouts/LoaderLayout'

const AUTH_STATE = {
  AUTHORIZED: 'authorized',
  UNAUTHORIZED: 'unauthorized',
  UNKNOWN: 'unknown',
}

const SecurityRoute = ({ children, restricted }) => {
  const [status, setStatus] = useState(AUTH_STATE.UNKNOWN)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setStatus(user ? AUTH_STATE.AUTHORIZED : AUTH_STATE.UNAUTHORIZED)
    })
  }, [])

  if(restricted) {
    switch (status) {
      case AUTH_STATE.UNKNOWN:
        return <LoaderLayout />
      case AUTH_STATE.AUTHORIZED:
        return children
      default:
        return <Redirect to={DEFAULT_UNAUTH_URL} />
    }
  }else{
    return children
  }
}

SecurityRoute.propTypes = {
  restricted: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired,
}

export default SecurityRoute
