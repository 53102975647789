import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Param from './Param'
import { getRecord } from '../../firebase/firestore/firestore'
import {getDivisionByRank} from '../../utils/rankDivisions'

const Container = styled.div`
  position: relative;
`

const TeamInformation = ({ teamId }) => {
  const [teamData, setTeamData] = useState(null)

  useEffect(() => {
    getRecord('teams', teamId).then(doc => setTeamData(doc.data()))
  }, [teamId])

  return(
    <>
      {
        teamData
        &&
        <Container>
          <Param title={'Gracz nr 1'} value={teamData.player1} trackerUrl={teamData.tracker1} />
          <Param title={'Gracz nr 2'} value={teamData.player2} trackerUrl={teamData.tracker2} />
          <Param title={'Discord leadera'} value={teamData.discordNick} />
          <Param title={'Gracz rezerwowy'} value={teamData.reservePlayer} trackerUrl={teamData.reserveTracker} />
          <Param title={'Nazwa drużyny'} value={teamData.teamName} />
          <Param title={'Najwyższa ranga'} value={teamData.highestRank} />
          <Param title={'Dywizja'} value={getDivisionByRank(teamData.highestRank)} />
        </Container>
      }
    </>
  )
}

TeamInformation.propTypes = {
  teamId: PropTypes.string.isRequired,
}

export default TeamInformation
