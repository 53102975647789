import {addNewRecord, updateRecord} from './firestore'
import firebase from '../firebase'

export const formatScheduleData = input => {
  const splitTeam1 = input.team1.includes('ID/NAME')
  const splitTeam2 = input.team2.includes('ID/NAME')
  return {
    ...input,
    time: firebase.firestore.Timestamp.fromDate(input.time.toDate()),
    team1: splitTeam1 ? input.team1.split('ID/NAME')[1] : input.team1,
    team2: splitTeam2 ? input.team2.split('ID/NAME')[1] : input.team2,
  }
}

export const addNewSchedule = data => addNewRecord('schedule', formatScheduleData(data))

export const updateSchedule = (id, data) => updateRecord('schedule', id, formatScheduleData(data))
