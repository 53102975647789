import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { message } from 'antd'

const getMessageText = id => {
  switch (id) {
    case 1: return 'Dlaczego to kliknąłeś?'
    case 2: return 'No i znowu to zrobiłeś!'
    case 3: return 'Liczysz na jakąś nagrodę?'
    case 4: return 'Przestań!'
    case 5: return 'Chcesz być VIPem, czy ja mam nim być?'
    case 6: return 'Jesteś z Krakowa?'
    case 7: return 'No dobra, jesteś VIPem z Krakowa...'
    default: return null
  }
}

const lastMessageId = 7

const Container = styled.div`
  text-align: center;
  cursor: pointer;
  max-width: 500px;
  margin: 0 auto;
`

const FooterWithVIPmode = ({ onVipModeEnabled }) => {
  const [VIPmodeClicks, setVIPmodeClicks] = useState(0)

  useEffect(() => {
    const msg = getMessageText(VIPmodeClicks)
    if(msg) message.info(msg)
    if(VIPmodeClicks === lastMessageId && onVipModeEnabled) onVipModeEnabled()
  }, [VIPmodeClicks, onVipModeEnabled])

  const onVersionClock = useCallback(() => {
    setVIPmodeClicks(c => c + 1)
  }, [])

  return(
    <Container onClick={onVersionClock}>
      kycu.live / ADANIT Software
    </Container>
  )
}

FooterWithVIPmode.propTypes = {
  onVipModeEnabled: PropTypes.func,
}

export default FooterWithVIPmode
