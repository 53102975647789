import {Input, Select} from 'antd'
import rocketLeagueRanks from '../utils/rocketLeagueRanks'
import React from 'react'

export const TRACKER_COMMON_URL = 'https://rocketleague.tracker.network/rocket-league/profile/'

const trackerUrlValidator = {
  validator: (_, value) => {
    if(
      value
      && value.toString().indexOf(TRACKER_COMMON_URL) === 0
      && value.length > TRACKER_COMMON_URL.length
    ) {
      return Promise.resolve()
    }
    return Promise.reject(
      new Error('To nie jest poprawny link do profilu TRN!')
    )
  }
}

const trimValues = (value) => typeof value === 'string' ? value.trim() : value

export const rocketLeaguePlatforms = [
  { title: 'Nie chcę podawać', value: 'unknown', color: '#a6a6a6' },
  { title: 'Komputer (klawiatura)', value: 'pc-keyb', color: '#d99956' },
  { title: 'Komputer (pad)', value: 'pc-pad', color: '#d07d26' },
  { title: 'Playstation (klawiatura)', value: 'ps-keyb', color: '#648dcc' },
  { title: 'Playstation (pad)', value: 'ps-pad', color: '#3470ce' },
  { title: 'Xbox (klawiatura)', value: 'xbox-keyb', color: '#7dc760' },
  { title: 'Xbox (pad)', value: 'xbox-pad', color: '#48bb17' },
  { title: 'Nintendo Switch', value: 'n-switch', color: '#b93939' },
]

const PlatformSelector = () => (
  <Select>
    {
      rocketLeaguePlatforms.map(({ title, value }) => (
        <Select.Option key={value} value={value}>
          {title}
        </Select.Option>
      ))
    }
  </Select>
)

const entryFormFields = [
  {
    label: 'Gracz nr 1',
    publicLabel: 'Nick gracza nr 1',
    name: 'player1',
    rules: [
      { required: true, message: 'Podaj nick gracza nr 1!', transform: trimValues },
    ],
    formItemProps: { maxLength: 30 },
    fieldComponent: <Input autoComplete={'off'} />,
  },
  {
    label: 'Gracz nr 2',
    publicLabel: 'Nick gracza nr 2',
    name: 'player2',
    rules: [
      { required: true, message: 'Podaj nick gracza nr 2!', transform: trimValues },
    ],
    formItemProps: { maxLength: 30 },
    fieldComponent: <Input autoComplete={'off'} />,
  },
  {
    label: 'Tracker nr 1',
    publicLabel: 'Link do profilu TRN gracza nr 1',
    name: 'tracker1',
    rules: [
      { required: true, message: 'Podaj link do trackera gracza nr 1!', transform: trimValues },
      trackerUrlValidator
    ],
    formItemProps: {},
    fieldComponent: <Input autoComplete={'off'} />,
  },
  {
    label: 'Tracker nr 2',
    publicLabel: 'Link do profilu TRN gracza nr 2',
    name: 'tracker2',
    rules: [
      { required: true, message: 'Podaj link do trackera gracza nr 2!', transform: trimValues },
      trackerUrlValidator
    ],
    formItemProps: {},
    fieldComponent: <Input autoComplete={'off'} />,
  },
  {
    label: 'Rezerwowy',
    publicLabel: 'Nick gracza rezerwowego (opcjonalny)',
    name: 'reservePlayer',
    rules: [],
    formItemProps: { maxLength: 30 },
    fieldComponent: <Input autoComplete={'off'} />,
  },
  {
    label: 'Tracker rezerwowego',
    publicLabel: 'Link do profilu TRN gracza rezerwowego (opcjonalny)',
    name: 'reserveTracker',
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value && getFieldValue('reservePlayer') && getFieldValue('reservePlayer') !== '') return Promise.reject(new Error('Podaj tracker gracza rezerwowego!'))
          if (value && getFieldValue('reservePlayer') && getFieldValue('reservePlayer') !== '') return trackerUrlValidator.validator(_, value)
          return Promise.resolve()
        },
        transform: trimValues
      })
    ],
    formItemProps: {},
    fieldComponent: <Input autoComplete={'off'} />,
  },
  {
    label: 'Ranga',
    publicLabel: 'Najwyższa ranga w drużynie',
    name: 'highestRank',
    rules: [
      { required: true, message: 'Wybierz rangę!' }
    ],
    formItemProps: {},
    fieldComponent:
      <Select>
        {
          Object.values(rocketLeagueRanks).map((rank) => (
            <Select.Option key={rank} value={rank}>
              {rank}
            </Select.Option>
          ))
        }
      </Select>,
  },
  {
    label: 'Nazwa drużyny',
    publicLabel: 'Nazwa drużyny',
    name: 'teamName',
    rules: [
      { required: true, message: 'Podaj nazwę drużyny!', transform: trimValues },
    ],
    formItemProps: { maxLength: 30 },
    fieldComponent: <Input autoComplete={'off'} />,
  },
  {
    label: 'Nick DC lidera',
    publicLabel: 'Nick Discord lidera zespołu',
    name: 'discordNick',
    rules: [
      { required: true, message: 'Podaj nick Discorda należący do lidera zespołu!', transform: trimValues },
    ],
    formItemProps: { maxLength: 30 },
    fieldComponent: <Input autoComplete={'off'} placeholder={'nickdiscord#1234'} />,
  },
  {
    label: 'Platforma nr 1',
    publicLabel: 'Platforma gracza nr 1 (dla statystyk)',
    name: 'platform1',
    rules: [
      { required: true, message: 'Podaj platformę z której grasz!' },
    ],
    formItemProps: {},
    fieldComponent: PlatformSelector(),
  },
  {
    label: 'Platforma nr 2',
    publicLabel: 'Platforma gracza nr 2 (dla statystyk)',
    name: 'platform2',
    rules: [
      { required: true, message: 'Podaj platformę z której grasz!' },
    ],
    formItemProps: {},
    fieldComponent: PlatformSelector(),
  },
  {
    label: 'Platforma rezerwowego',
    publicLabel: 'Platforma gracza rezerwowego (dla statystyk)',
    name: 'reservePlatform',
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value && getFieldValue('reservePlayer') && getFieldValue('reservePlayer') !== '') return Promise.reject(new Error('Podaj platformę z której grasz!'))
          return Promise.resolve()
        },
        transform: trimValues
      })
    ],
    formItemProps: {},
    fieldComponent: PlatformSelector(),
  },
]

export default entryFormFields
