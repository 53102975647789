import firebase from '../firebase'
import {rocketLeaguePlatforms} from '../../config/entryFormFields'

export const analyzePlayerPlatforms = () => {
  return firebase
    .firestore()
    .collection('teams')
    .get()
    .then(snap => {
      const stats = []
      const findPlatform = (platformId) => {
        if (!platformId) return
        const el = stats.find(i => i.platformId === platformId)
        if(el) {
          el.value += 1
        }else{
          stats.push({
            platformId,
            value: 1,
          })
        }
      }
      snap.docs.forEach(doc => {
        const { platform1, platform2, reservePlatform } = doc.data()
        findPlatform(platform1)
        findPlatform(platform2)
        findPlatform(reservePlatform)
      })
      stats.forEach(i => {
        const platformInfo = rocketLeaguePlatforms.find(p => p.value === i.platformId)
        if(platformInfo) {
          i.color = platformInfo.color
          i.title = platformInfo.title
        }
      })
      return stats
    })
}

export const analyzeTrackerPlatforms = () => {
  return firebase
    .firestore()
    .collection('teams')
    .get()
    .then(snap => {
      const platforms = {
        playstation: 0,
        xbox: 0,
        steam: 0,
        epic: 0,
        switch: 0,
        unknown: 0,
      }
      const findPlatformId = (trackerUrl) => {
        if(!trackerUrl) return
        if (trackerUrl.includes('/psn/')) {
          platforms.playstation += 1
        } else if (trackerUrl.includes('/xbl/')) {
          platforms.xbox += 1
        } else if (trackerUrl.includes('/steam/')) {
          platforms.steam += 1
        } else if (trackerUrl.includes('/epic/')) {
          platforms.epic += 1
        } else if (trackerUrl.includes('/switch/')) {
          platforms.switch += 1
        } else {
          platforms.unknown += 1
        }
      }
      snap.docs.forEach(doc => {
        const { tracker1, tracker2, reserveTracker } = doc.data()
        findPlatformId(tracker1)
        findPlatformId(tracker2)
        findPlatformId(reserveTracker)
      })
      const stats = [
        { title: 'PlayStation', value: platforms.playstation, color: '#4471c9' },
        { title: 'Xbox', value: platforms.xbox, color: '#38b038' },
        { title: 'Steam', value: platforms.steam, color: '#be8c45' },
        { title: 'Epic', value: platforms.epic, color: '#a059c9' },
        { title: 'Switch', value: platforms.switch, color: '#c94f31' },
        { title: 'Unknown', value: platforms.unknown, color: '#9b9b9b' },
      ]
      return stats.filter(s => s.value > 0)
    })
}
