import React from 'react'
import styled from 'styled-components'
import PetTheOzzy from '../../assets/pettheozzy.gif'

const Container = styled.div`
  text-align: center;
  margin: 50px auto;
`

const MainPageView = () => {

  return(
    <Container>
      <img src={PetTheOzzy} alt={'Ozzy'} />
    </Container>
  )
}

export default MainPageView
