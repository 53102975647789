import React, { useState, useCallback } from 'react'
import {Button, Checkbox, Card, message} from 'antd'
import { getFullCollection } from '../../firebase/firestore/firestore'
import copy from 'copy-to-clipboard'
import BulkAddImage from '../../assets/challonge-bulk-add.jpg'
import {divisions, getDivisionByRank} from '../../utils/rankDivisions'

const cardStyle = {
  maxWidth: 800
}

const divisionsList = divisions.map(d => d.name)

const TeamListExport = () => {
  const [active, setActive] = useState(true)
  const [selected, setSelected] = useState(divisionsList)

  const onClick = useCallback(() => {
    setActive(false)
    setTimeout(() => {
      getFullCollection('teams')
        .then(docs => {
          const filtered = docs.filter(t => {
            const teamDivision = getDivisionByRank(t.highestRank)
            return teamDivision && selected.includes(teamDivision)
          })
          copy(filtered.map(d => d.teamName).join('\r\n'))
          message.info(`Dane zostały skopiowane do schowka! Wszystkich: ${docs.length} Przefiltrowanych: ${filtered.length}`)
        })
        .catch(err => {
          console.error(err)
          message.error('Wystąpił błąd podczas eksportowania danych!')
        })
        .finally(() => {
          setTimeout(() => setActive(true), 5000)
        })
    }, 500)
  }, [selected])

  return(
    <Card title='Lista drużyn' style={cardStyle}>
      Wybierz dywizje do eksportu:<br/>
      <Checkbox.Group
        options={divisionsList}
        defaultValue={divisionsList}
        onChange={checked => setSelected(checked)}
      />
      <div style={{ margin: '15px' }}>
        <Button type={'primary'} onClick={onClick} disabled={!active}>Generuj listę drużyn</Button>
      </div>
      <p>
        Przydatne do przygotowania losowania drużyn w grupach lub do automatycznego dodawania drużyn do systemu Challonge - wystarczy wkleić wygenerowaną listę do pola "Bulk Add":
      </p>
      <img src={BulkAddImage} alt={'challonge'} style={{ maxWidth: '100%' }} />
    </Card>
  )
}

const DashboardDataExportView = () => {

  return(
    <>
      <TeamListExport />
    </>
  )
}

export default DashboardDataExportView
