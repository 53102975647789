import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {listenCollection} from '../../firebase/firestore/firestore'
import {BOX_STATE} from '../../firebase/firestore/mysteryBox.firestore'
import BoxOpeningModal from './BoxOpeningModal'

const OutsideContainer = styled.div`
  overflow: auto;
  margin: 20px 0;
`

const InsideContainer = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(${props => props['gridWidth']}, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  min-width: 580px;
  max-width: 840px;
  aspect-ratio: 1;
`

const BoxContainer = styled.div`
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const BoxBase = styled.div`
  background-color: #68D472;
  width: 150px;
  height: 150px;
`

const BoxBaseText = styled.div`
  color: #000;
  font-size: 0.8rem;
  margin-top: 50%;
  padding: 0 5px;
  text-align: center;
  transform: translateY(-50%);
  word-wrap: anywhere;
`

const BoxCover = styled.div`
  background-color: #1890ff;
  color: #fff;
  font-size: 5rem;
  text-align: center;
  aspect-ratio: 1;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  transition:  0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    top: -10px;
    left: -10px;
  }
`

const MysteryBoxGrid = () => {
  const [list, setList] = useState(null)
  const [selectedBox, setSelectedBox] = useState(null)

  useEffect(() => (
    listenCollection('mysteryBox', d => {
      const sortedByBoxNumber = d.sort((b1, b2) => (b1.box - b2.box))
      setList(sortedByBoxNumber)
    })
  ), [])

  if(!list) {
    return (
      <i>Brak danych</i>
    )
  }
  const gridWidth = Math.ceil(Math.sqrt(list.length))
  return(
    <>
      <OutsideContainer>
        <InsideContainer gridWidth={gridWidth}>
          {
            list.map(box => (
              <BoxContainer key={box.docId}>
                <BoxBase>
                  {
                    box.state === BOX_STATE.CLOSE
                      ?
                      <BoxCover onClick={() => setSelectedBox(box.docId)}>{box.box}</BoxCover>
                      :
                      <BoxBaseText>{box.value}</BoxBaseText>
                  }
                </BoxBase>
              </BoxContainer>
            ))
          }
        </InsideContainer>
      </OutsideContainer>
      {
        selectedBox
        &&
        <BoxOpeningModal
          boxData={list.find(i => i.docId === selectedBox)}
          onHide={() => setSelectedBox(null)}
        />}
    </>
  )
}

export default MysteryBoxGrid
