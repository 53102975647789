import React  from 'react'
import {Modal, Tag} from 'antd'
import FirestoreTableBridge from '../FirestoreTableBridge/FirestoreTableBridge'
import {BOX_STATE} from '../../firebase/firestore/mysteryBox.firestore'

const columns = [
  {
    title: 'Nr',
    dataIndex: 'no',
    render: v => v,
  },
  {
    title: 'Box',
    dataIndex: 'box',
    render: (v, record) => {
      if(record.state === BOX_STATE.OPEN) return v
      return '?'
    },
  },
  {
    title: 'Status',
    dataIndex: 'state',
    render: v => {
      switch (v) {
        case BOX_STATE.OPEN: return <Tag color={'red'}>Otwarty</Tag>
        case BOX_STATE.CLOSE: return <Tag color={'geekblue'}>Zamknięty</Tag>
        default: return <Tag color={'gray'}>{v}</Tag>
      }
    },
  },
  {
    title: 'Zwycięzca',
    dataIndex: 'receiver',
    render: v => (v ? v : <i>(brak)</i>),
  },
  {
    title: 'Zawartość',
    dataIndex: 'value',
    render: v => v,
  },
]

const tableConfig = {
  filterOption: [
    {
      key: 'state',
      name: 'Status',
      arrayContainsMode: false,
      defaultIdx: 0,
      values: [
        { text: 'Wszystkie', value: null },
        { text: 'Otwarte', value: BOX_STATE.OPEN },
        { text: 'Zamknięte', value: BOX_STATE.CLOSE },
      ],
    },
  ],
}

const ListPreviewModal = ({ onHide }) => {

  return(
    <Modal
      title={'Zawartość i wygrane'}
      visible={true}
      onOk={onHide}
      onCancel={onHide}
      footer={null}
      width={1000}
    >
      <FirestoreTableBridge
        dataSourcePath={`mysteryBox`}
        defaultSortMethod={{ key: 'no', direction: 'asc' }}
        columns={columns}
        hideActionColumn={true}
        itemsPerPage={false}
        tableConfig={tableConfig}
      />
    </Modal>
  )
}

export default ListPreviewModal
