import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Space, Popconfirm } from 'antd'
import {AppstoreAddOutlined} from '@ant-design/icons'
import MysteryBoxGrid from '../../components/MysteryBoxGrid/MysteryBoxGrid'
import NewListModal from '../../components/MysteryBoxGrid/NewListModal'
import ListPreviewModal from '../../components/MysteryBoxGrid/ListPreviewModal'

const TopButtons = styled.div`
  text-align: right;
`

const DashboardWidgetsView = () => {
  const [newListModal, setNewListModal] = useState(false)
  const [listPreviewModal, setListPreviewModal] = useState(false)

  return(
    <>
      {newListModal && <NewListModal onHide={() => setNewListModal(false)} />}
      {listPreviewModal && <ListPreviewModal onHide={() => setListPreviewModal(false)} />}
      <TopButtons>
        <Space>
          <Popconfirm
            key={'newmystery'}
            title={'Czy chcesz zresetować listę niespodzianek?'}
            onConfirm={() => setNewListModal(true)}
            okText={'Tak'}
            cancelText={'Nie'}
          >
            <Button
              type={'primary'}
              icon={<AppstoreAddOutlined />}
            >
              Nowe rozdanie
            </Button>
          </Popconfirm>
          <Button onClick={() => setListPreviewModal(true)}>
            Zawartość i wygrane
          </Button>
        </Space>
      </TopButtons>
      <MysteryBoxGrid />
      </>
      )
}

export default DashboardWidgetsView
