import React from 'react'
import {Select} from 'antd'
import {useTeamsContext} from '../../context/TeamsContext'

const TeamSelector = (props) => {
  const teams = useTeamsContext()

  return(
    <Select {...props}>
      {
        teams.map(team => (
          <Select.Option
            key={team.id}
            value={team.id + 'ID/NAME' + team.teamName}
          >
            <b>{team.teamName}</b> ({team.player1}, {team.player2})
          </Select.Option>
        ))
      }
    </Select>
  )
}

export default TeamSelector
