import React, { useRef, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'antd/es/modal/Modal'
import './captchaStyles.css'
import {Alert} from 'antd'

const FormCaptcha = ({ isOpen, onResult }) => {
  const [value, setValue] = useState(0)
  const [successMsg, setSuccessMsg] = useState(false)
  const rangeRef = useRef()

  useEffect(() => {
    if(isOpen) {
      setValue(0)
      setSuccessMsg(false)
      rangeRef.current.disabled = false;
    }
  }, [isOpen])

  const onEnd = useCallback(() => {
    if (value >= 100) {
      rangeRef.current.disabled = true;
      setSuccessMsg(true)
      setTimeout(() => onResult(true), 1000)
    } else {
      setValue(0)
    }
  }, [value, onResult])

  return(
    <Modal
      title='Potwierdzenie'
      centered
      visible={isOpen}
      onCancel={() => onResult(false)}
      footer={null}
    >
      Przeciągnij suwak na prawą stronę, aby potwierdzić, że nie jesteś robotem:
      <input
          type='range'
          ref={rangeRef}
          className='captcha-plane'
          value={value}
          onChange={e => setValue(e.target.value)}
          min='0'
          max='100'
          onMouseUp={onEnd}
          onTouchEnd={onEnd}
      />
      {
        successMsg
        &&
        <Alert type={'info'} message={'Wysyłanie zgłoszenia...'} />
      }
    </Modal>
  )
}

FormCaptcha.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onResult: PropTypes.func.isRequired,
}

export default FormCaptcha
