import React, { useState, useCallback } from 'react'
import FirestoreTableBridge from '../../components/FirestoreTableBridge/FirestoreTableBridge'
import {Button, Checkbox, DatePicker, Input, message, Popconfirm, Select} from 'antd'
import copy from 'copy-to-clipboard'
import {addNewSchedule, formatScheduleData, updateSchedule} from '../../firebase/firestore/schedule.firestore'
import {timestampToString} from '../../utils/dateConverter'
import moment from 'moment'
import { PlayCircleOutlined } from '@ant-design/icons'
import TeamSelector from '../../components/TeamSelector/TeamSelector'
import {selectActiveMatch} from '../../firebase/firestore/match.firestore'
import { useHistory } from 'react-router-dom'

const TeamNameWithCopy = ({v}) => (
  <>
    {v}<br />
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    (<a onClick={() => { copy(v); message.info('Skopiowano do schowka') }}>Skopiuj nazwę</a>)
  </>
)

const columns = [
  {
    title: 'Data spotkania',
    dataIndex: 'time',
    render: v => timestampToString(v),
  },
  {
    title: 'Lewa strona',
    dataIndex: 'team1',
    render: v => <TeamNameWithCopy v={v} />,
  },
  {
    title: 'Prawa strona',
    dataIndex: 'team2',
    render: v => <TeamNameWithCopy v={v} />,
  },
  {
    title: 'Best of',
    dataIndex: 'bestOf',
    render: v => v,
  },
]

const injectHistoryToTableConfig = history => ({
  sortOption: [
    {
      key: 'teamName',
      name: 'Nazwa drużyny',
      ascDirection: true,
      descDirection: true,
      extraFields: [],
    },
  ],
  newItemButton: true,
  actions: {
    editItemButton: true,
    deleteItemButton: true,
    custom: (idx, recordData, deleteItem) => ([
      <Popconfirm
        key={'accept'}
        title={'Czy chcesz uruchomić teraz ten mecz?'}
        onConfirm={() => {
          selectActiveMatch(
            recordData.time,
            recordData.bestOf,
            recordData.key,
            recordData.team1,
            recordData.team2,
            recordData.teamId1,
            recordData.teamId2
          )
            .then(() => {
              history.push('/dashboard/match-panel')
              return message.success('Mecz został aktywowany!')
            })
        }}
        okText={'Uruchom mecz'}
        cancelText={'Anuluj'}
      >
        <Button size={'large'} icon={<PlayCircleOutlined />} type={'primary'}>
          Rozpocznij mecz
        </Button>
      </Popconfirm>,
    ]),
  },
  newFormInitialValues: {
    bestOf: 1,
    time: moment(),
  },
  onNewItemFormSubmit: (data, accept) => {
    addNewSchedule(data)
      .then(() => {
        accept()
        return message.success('Dodano nowy zapis')
      })
      .catch(() => message.error('Błąd aktualizacji!'))
  },
  onEditItemFormSubmit: (data, key, accept) => {
    updateSchedule(key, data)
      .then(() => {
        accept(() => formatScheduleData(data))
        return message.success('Zapis został zaktualizowany')
      })
      .catch(() => message.error('Błąd aktualizacji!'))
  },
  onFormChange: (change, formRef, editMode) => {
    if(Object.hasOwnProperty.call(change, 'team1')) {
      formRef.setFieldsValue({
        teamId1: change.team1.split('ID/NAME')[0]
      })
    }
    if(Object.hasOwnProperty.call(change, 'team2')) {
      formRef.setFieldsValue({
        teamId2: change.team2.split('ID/NAME')[0]
      })
    }
  },
  formFields: [
    {
      label: 'Best of',
      name: 'bestOf',
      rules: [{ required: true, message: 'Podaj liczbę best of!' }],
      formItemProps: {},
      fieldComponent:
        <Select>
          {[1,3,5,7,9].map(i => <Select.Option key={i} value={i}>{i} (max wygranych: {(i+1)/2})</Select.Option>)}
        </Select>,
    },
    {
      label: 'Czas spotkania',
      name: 'time',
      rules: [{ required: true, message: 'Podaj czas spotkania!' }],
      formItemProps: {},
      fieldComponent:
        <DatePicker
          format={'DD-MM-YYYY HH:mm'}
          minuteStep={5}
          showSecond={false}
          showTime={{ defaultValue: moment('12:00', 'HH:mm') }}
        />,
    },
    {
      label: 'Lewa strona',
      name: 'team1',
      rules: [{ required: true, message: 'Wybierz drużynę!' }],
      formItemProps: {},
      fieldComponent: <TeamSelector name={'team1'} />,
    },
    {
      label: 'Prawa strona',
      name: 'team2',
      rules: [{ required: true, message: 'Wybierz drużynę!' }],
      formItemProps: {},
      fieldComponent: <TeamSelector name={'team2'} />,
    },
    {
      label: 'ID lewej strony',
      name: 'teamId1',
      rules: [],
      formItemProps: {},
      fieldComponent: <Input disabled />,
    },
    {
      label: 'ID prawej strony',
      name: 'teamId2',
      rules: [],
      formItemProps: {},
      fieldComponent: <Input disabled />,
    },
  ],
})

const DashboardScheduleView = () => {
  const [onlyToday, setOnlyToday] = useState(false)
  const history = useHistory()

  const staticModifiers = useCallback(ref => {
    const from = new Date()
    from.setHours(0, 0, 0)
    const to = new Date()
    to.setHours(23, 59, 59)
    return ref.where('time', '>=', from).where('time', '<=', to)
  }, [])

  return(
    <>
      <Checkbox
        checked={onlyToday}
        onChange={() => setOnlyToday(c => !c)}
        style={{ margin: '10px' }}
      >
        Pokaż tylko dzisiejsze mecze
      </Checkbox>
      <FirestoreTableBridge
        dataSourcePath={`schedule`}
        defaultSortMethod={{ key: 'time', direction: 'asc' }}
        columns={columns}
        tableConfig={injectHistoryToTableConfig(history)}
        staticModifiers={onlyToday ? staticModifiers : undefined}
        itemsPerPage={false}
      />
    </>
  )
}

export default DashboardScheduleView
