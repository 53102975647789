import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {dashboardRoutes} from '../../config/routes'
import {TeamsProvider} from '../../context/TeamsContext'

const DashboardRouter = () => {

  return(
    <TeamsProvider>
      <Switch>
        {
          dashboardRoutes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={props => <route.component {...props} />}
            />
          ))
        }
        <Route>
          404 - MODULE NOT FOUND
        </Route>
      </Switch>
    </TeamsProvider>
  )
}

export default DashboardRouter
