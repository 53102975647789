import firebase from '../firebase/firebase'
import addLeadingZero from './addLeadingZero'
import moment from 'moment'

export const timestampToDate = timestamp => {
  if(isNaN(timestamp.seconds) || isNaN(timestamp.nanoseconds)) new Date()
  return (new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds)).toDate()
}

export const timestampToString = (timestamp, showDate = true, showTime = true, showSeconds = false) => {
  if(isNaN(timestamp.seconds) || isNaN(timestamp.nanoseconds)) return '--:--:----'
  const date = (new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds)).toDate()
  const dd = addLeadingZero(date.getDate())
  const mm = addLeadingZero(date.getMonth() + 1)
  const yyyy = date.getFullYear()
  const hr = addLeadingZero(date.getHours())
  const min = addLeadingZero(date.getMinutes())
  const sec = addLeadingZero(date.getSeconds())
  let s = ''
  if(showDate) s += `${dd}-${mm}-${yyyy}`
  if(showDate && showTime) s += ' '
  if(showTime) s += `${hr}:${min}${showSeconds ? `:${sec}` : ''}`
  return s
}

export const getTimestampDifference = (d1, d2, allowNegativeValues = false) => {
  if(isNaN(d1.seconds) || isNaN(d1.nanoseconds)) return '--:--:--'
  if(isNaN(d2.seconds) || isNaN(d2.nanoseconds)) return '--:--:--'
  const date1 = (new firebase.firestore.Timestamp(d1.seconds, d1.nanoseconds)).toDate()
  const date2 = (new firebase.firestore.Timestamp(d2.seconds, d2.nanoseconds)).toDate()
  let seconds = Math.floor((date1 - date2) / 1000)
  if(seconds < 0 && !allowNegativeValues) seconds = 0
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(seconds / 3600)
  const days = Math.floor(seconds / 86400)
  if(days > 0) return `${days} dni ${hours} godzin`
  if(hours > 0) return `${hours}:${addLeadingZero(minutes)}:${addLeadingZero(seconds)}`
  return `${minutes}:${addLeadingZero(seconds)}`
}

export const findAndConvertTimestampToMoment = input => {
  const output = {}
  Object.keys(input).forEach(key => {
    const obj = input[key]
    if(obj) {
      if(Object.hasOwnProperty.call(obj, 'seconds') && Object.hasOwnProperty.call(obj, 'nanoseconds')) {
        output[key] = moment(new firebase.firestore.Timestamp(obj.seconds, obj.nanoseconds).toDate())
      }else{
        output[key] = obj
      }
    }
  })
  return output
}

export const findAndConvertMomentToTimestamp = input => {
  const output = {}
  Object.keys(input).forEach(key => {
    const obj = input[key]
    if(moment.isMoment(obj)) {
      output[key] = firebase.firestore.Timestamp.fromDate(obj.toDate())
    }else{
      output[key] = obj
    }
  })
  return output
}
