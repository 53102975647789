import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import {Modal, Button, Input, message} from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import {generateNewBoxList} from '../../firebase/firestore/mysteryBox.firestore'

const MAX_LIST_SIZE = 16

const ItemContainer = styled.div`
  margin: 8px 0;
`

const ListItemInput = ({ idx, item, onChange, onDelete }) => (
  <ItemContainer>
    <Input.Group compact>
      <Input
        style={{ width: 'calc(100% - 100px)' }}
        placeholder={`Pozycja nr ${idx + 1}`}
        value={item}
        onChange={e => onChange(idx, e.target.value)}
      />
      <Button
        style={{ width: '100px' }}
        onClick={() => onDelete(idx)}
      >
        Usuń
      </Button>
    </Input.Group>
  </ItemContainer>
)

const NewListModal = ({ onHide }) => {
  const [list, setList] = useState(['', '', ''])

  const listSize = list.length
  const gridWidth = Math.ceil(Math.sqrt(listSize))

  const onSubmit = () => {
    const data = list.filter(i => i)
    if(data.length < 3) {
      message.error('Musisz podać minimum 3 pozycje!')
    }else{
      message.info('Wysyłanie...')
      generateNewBoxList(data).then(() => {
        message.success('Lista została załadowana!')
        onHide()
      })
    }
  }

  const onChange = useCallback((idx, value) => {
    setList(current => {
      const arr = [...current]
      arr[idx] = value
      return arr
    })
  }, [])

  const onDelete = useCallback((idx) => {
    setList(current => {
      const arr = [...current]
      arr.splice(idx, 1)
      return arr
    })
  }, [])

  return(
    <Modal
      title={'Nowe rozdanie'}
      visible={true}
      okText={'Zatwierdź'}
      cancelText={'Anuluj'}
      onOk={onSubmit}
      onCancel={onHide}
      width={800}
    >
      {
        listSize > 0
        &&
        <>Rozmiar: {listSize} | Rozmiar siatki: {gridWidth}</>
      }
      {
        list.map((item, idx) => (
          <ListItemInput idx={idx} item={item} onChange={onChange} onDelete={onDelete} />
        ))
      }
      <Button
        onClick={() => setList(c => ([...c, '']))}
        disabled={listSize >= MAX_LIST_SIZE}
        icon={<PlusOutlined />}
        block
      >
        Dodaj pozycję
      </Button>
    </Modal>
  )
}

export default NewListModal
