import firebase from '../firebase'

export const defaultMatchState = {
  enabled: false,
  teamWon1: 0,
  teamWon2: 0,
}

export const listenMatchDocument = onChange => {
  const docRef = firebase.firestore().collection('config').doc('match')

  return docRef.get()
    .then(doc => {
      if(doc.exists) onChange({ ...defaultMatchState, ...doc.data() })
      return docRef.onSnapshot(snap => {
        onChange({ ...defaultMatchState, ...snap.data() })
      }, err => {
        onChange(defaultMatchState)
      })
    })
}

export const selectActiveMatch = (time, bestOf, matchId, team1, team2, teamId1, teamId2) => {
  return firebase
    .firestore()
    .collection('config')
    .doc('match')
    .set({
      ...defaultMatchState,
      enabled: true,
      time,
      bestOf,
      matchId,
      team1,
      team2,
      teamId1,
      teamId2,
    })
}

export const closeActiveMatch = () => {
  return firebase
    .firestore()
    .collection('config')
    .doc('match')
    .set(defaultMatchState)
}

export const updateActiveMatch = data => {
  return firebase
    .firestore()
    .collection('config')
    .doc('match')
    .set(data, { merge: true })
}
