import React from 'react'
import styled from 'styled-components'
import { Form, Input, Button, message } from 'antd'
import firebase from '../../firebase/firebase'
import { useHistory } from 'react-router-dom'
import {DEFAULT_AUTH_URL} from '../../config/routes'

const FormContainer = styled.div`
  max-width: 450px;
  margin: 20px auto;
`

const SignInView = () => {
  const history = useHistory()

  const onFinish = ({ email, password }) => {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push(DEFAULT_AUTH_URL)
      })
      .catch(err => {
        console.error(err)
        switch (err.code) {
          case 'auth/user-not-found':
          case 'auth/wrong-password':
            return message.error('Podano błędne dane logowania!')
          default:
            return message.error(`Błąd logowania! Kod: ${err.code}`)
        }
      })
  }

  return(
    <FormContainer>
      <Form
        name='signin'
        layout='vertical'
        onFinish={onFinish}
      >
        <Form.Item
          label='E-mail'
          name='email'
          rules={[
            {
              required: true,
              message: 'Podaj adres e-mail!',
            },
            {
              required: true,
              type: "email",
              message: "Podano niewłaściwy adres e-mail!"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Hasło'
          name='password'
          rules={[
            {
              required: true,
              message: 'Podaj hasło dostępu!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 12,
          }}
        >
          <Button type='primary' htmlType='submit' block>
            Zaloguj
          </Button>
        </Form.Item>
      </Form>
    </FormContainer>
  )
}

export default SignInView
