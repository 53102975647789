import firebase from '../firebase'

export const getEntryFormState = () => {
  return firebase
    .firestore()
    .collection('config')
    .doc('entries')
    .get()
    .then(doc => doc.data().enabled)
}

export const setEntryFormState = state => {
  return firebase
    .firestore()
    .collection('config')
    .doc('entries')
    .set({
      enabled: state
    }, { merge: true })
}
