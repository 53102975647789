import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import {Button, Collapse, message} from 'antd'

const content = [
  {
    title: 'Daj prezent rabbitowi',
    description: 'Podaruj rabbitowi to, na co czekał od dawna i wielokrotnie się dopominał - bana. kycuUj',
    messageText: '500: Bład wewnętrzny Twitcha - rabbit jest nie do ruszenia',
    messageType: 'error',
  },
  {
    title: 'Otwórz drzwi',
    description: 'Odblokuj zamek w drzwiach na strychu, aby można było zostawić w nim prezenty lub jeśli Simple się w nim zatrzasnął.',
    messageText: 'Zamek został odblokowany',
    executeAfterMessage: () => {
      setTimeout(() => message.warn('System bezpieczeństwa: wykryto ruch przed drzwiami na strychu'), 3000)
      setTimeout(() => message.info('Zamek został zablokowany'), 6000)
    }
  },
  {
    title: 'Przelew heheszków',
    description: 'Przelej na konto adana nagrodę w postaci heheszków za jego ciężką pracę, bycie wzorowym członkiem społeczności oraz regularną obecność na streamach. PeepoGlad',
    messageText: 'Przelano 10 000 heheszków na konto adan2013_pol',
    messageType: 'success',
    executeAfterMessage: () => {
      setTimeout(() => message.info('Użytkownik adan2013_pol przekazał 10 000 heheszków na cele charytatywne'), 5000)
    }
  },
  {
    title: 'Uruchom Hype Train',
    description: 'Zakup 50 losowych subskrypcji w prezencie, a kwotę pobierz z konta bankowego przypisanego do użytkownika "Zazanek".',
    messageText: 'Przelew aktywowany. Trwa zakup subskrypcji...',
    messageType: 'success',
    executeAfterMessage: () => {
      setTimeout(() => message.error('Komunikat banku: brak środków na koncie'), 3000)
      setTimeout(() => message.error('Zakup subskrypcji anulowany'), 3000)
    }
  },
  {
    title: 'Napraw MFS',
    description: 'Zresetuj konfigurację gry Microsoft Flight Simulator, aby naprawić problem z nieuruchamianiem się aplikacji.',
    messageText: 'Hah, Microsoft go brrrrrrrrrr',
    messageType: 'warn',
  },
  {
    title: 'Zoptymalizuj grę Star Citizen',
    description: 'Uruchom automatyczną głęboką optymalizację kodu gry Star Citizen. Zaawanoswana technologia AI dokona w kodzie zmian, które umożliwią uzyskanie stabilnych 120 FPS, zredukują migotanie tekstur oraz sprawią, że durne panele przy drzwiach nie będą gasnąć, jak się do nich podchodzi. Uruchomienie tej opcji pozwoli uniknąć przyszłych 25 lat developmentu tego tytułu.',
    messageText: 'Ni uja, Star Citizen jest spier*olony na amen',
    messageType: 'error',
  },
]

const FooterButtons = styled.div`
  text-align: right;
  margin-top: 10px;
`

const Action = ({ config, ...props }) => {
  const [fakeLoading, setFakeLoading] = useState(false)

  const onExecuteClick = useCallback(() => {
    setFakeLoading(true)
    setTimeout(() => {
      setFakeLoading(false)
      message[config.messageType || 'info'](config.messageText)
      if(config.executeAfterMessage) config.executeAfterMessage()
    }, 3000)
  }, [config])

  return(
    <Collapse.Panel {...props} header={config.title}>
      {config.description}
      <FooterButtons>
        <Button type={'primary'} loading={fakeLoading} onClick={onExecuteClick}>Wykonaj</Button>
      </FooterButtons>
    </Collapse.Panel>
  )
}

const DashboardVipModeView = () => {

  return(
    <>
      <h1>Tryb VIPa z Krakowa</h1>
      <Collapse accordion style={{ maxWidth: '650px' }}>
        {
          content.map(i => (
            <Action key={i.title} config={i} />
          ))
        }
      </Collapse>
    </>
  )
}

export default DashboardVipModeView
