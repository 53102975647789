import { default as R } from './rocketLeagueRanks'
import styled from 'styled-components'

export const divisions = [
  {
    name: 'C',
    color: '#56c234',
    ranks: [
      R.bronze,
      R.silver,
      R.gold
    ]
  },
  {
    name: 'B',
    color: '#da952b',
    ranks: [
      R.platinum,
      R.diamond
    ]
  },
  {
    name: 'A',
    color: '#be4747',
    ranks: [
      R.champion,
      R.grandChampion
    ]
  },
  {
    name: 'S',
    color: '#3fd7d7',
    ranks: [
      R.supersonicLegend
    ]
  }
]

export const getDivisionByRank = rank => {
  const division = divisions.find(d => d.ranks.includes(rank))
  if (division) return division.name
  return null
}

export const SmallDivisionTag = styled.div`
  display: inline-block;
  font-size: 0.8rem;
  margin: ${props => props.withMargin ? '0 10px' : '0'};
  padding: 0 8px;
  background-color: ${props => props.color || '#cfcfcf'};
  color: #000
`
