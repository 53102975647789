import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background-color: #5e5e5e;
  width: 100vw;
  height: 100vh;
`

const Spinner = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  display: block;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to { transform: rotate(360deg); }
  }
`

const LoaderLayout = () => (
  <Container>
    <Spinner />
  </Container>
)

export default LoaderLayout
