import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {closeActiveMatch, listenMatchDocument} from "../../../firebase/firestore/match.firestore";
import {PauseCircleOutlined, PlayCircleOutlined} from "@ant-design/icons";
import {Button, Space} from "antd";
import {BigText, FooterButtons, PanelBlock, SmallText, Title} from "./CommonElements";

export const CurrentMatchPanel = () => {
    const [state, setState] = useState(null)
    const history = useHistory()

    useEffect(() => listenMatchDocument(state => {
        setState(state)
    }), [])

    if(!state) {
        return(
            <PanelBlock>
                <Title>Panel meczu</Title>
                <SmallText>Pobieranie danych...</SmallText>
            </PanelBlock>
        )
    }

    return(
        <PanelBlock>
            <Title>Panel meczu</Title>
            {
                (state && state.enabled)
                    ?
                    <>
                        <BigText color={'#159f15'}><PlayCircleOutlined /></BigText>
                        <SmallText>{state.team1} VS {state.team2}</SmallText>
                    </>
                    :
                    <BigText color={'#939393'}><PauseCircleOutlined /></BigText>
            }
            <FooterButtons>
                <Space>
                    <Button size={'small'} disabled={!(state && state.enabled)} danger onClick={closeActiveMatch}>
                        Zakończ mecz
                    </Button>
                    <Button size={'small'} type={'primary'} onClick={() => history.push('/dashboard/match-panel')}>
                        Otwórz
                    </Button>
                </Space>
            </FooterButtons>
        </PanelBlock>
    )
}