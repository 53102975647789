import React from 'react'
import styled from 'styled-components'
import KycuLiveLogo from '../assets/kycu-live-logo.png'
import RedarkLogo from '../assets/redark-logo.png'

const Background = styled.div`
  background-color: #444444;
  min-height: 100vh;
`

const LogoContainer = styled.div`
  text-align: center;
  margin: 20px auto;
  
  img {
    max-width: 200px;
  }
`

const Content = styled.div`
  background-color: #ececec;
  max-width: 1300px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 15px 25px;
`

const Footer = styled.div`
  text-align: center;
  
  img {
    margin: 5px auto 10px;
    max-width: 140px;
  }
`

const SimplePageLayout = ({ children }) => (
  <Background>
    <Content>
      <LogoContainer>
        <img src={KycuLiveLogo} alt={'kycu live logo'} />
      </LogoContainer>
      {children}
      <Footer>
        <i>Autor systemu:</i><br/>
        Daniel adan2013 Alberski<br/>
        <a href='https://redark.pl' target="_blank" rel="noopener noreferrer">
          <img src={RedarkLogo} alt={'redark'} />
        </a>
      </Footer>
    </Content>
  </Background>
)

export default SimplePageLayout
