import React, {useState, useCallback, useMemo} from 'react'
import styled from 'styled-components'
import { Layout, Menu } from 'antd'
import KycuLiveLogo from '../assets/kycu-live-logo.png'
import {useHistory, useLocation} from 'react-router-dom'
import { sideMenu } from '../config/sideMenu'
import FooterWithVIPmode from '../components/VersionNumberWithVIPmode/FooterWithVIPmode'

const { Content, Footer, Sider } = Layout

const ContentContainer = styled.div`
  padding: 20px;
`

const Logo = styled.img`
  display: block;
  margin: 20px auto 15px;
  max-width: 90%;
`

const DashboardLayout = ({ children }) => {
  const location = useLocation()
  const history = useHistory()
  const [vipMode, setVipMode] = useState(false)
  const onVipModeEnabled = useCallback(() => setVipMode(true), [])
  const [collapsed, setCollapsed] = useState(false)
  const onCollapse = useCallback(() => setCollapsed(c => !c), [])

  const currentPathKey = useMemo(() => {
    const currentItem = sideMenu.find(i => i.path === location.pathname)
    if(currentItem) return currentItem.key
  }, [location])

  return(
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <Logo src={KycuLiveLogo} alt={'kycu live logo'} />
        <Menu theme='dark' selectedKeys={currentPathKey} mode='inline'>
          {
            sideMenu
              .filter(i => vipMode || !i.visibleOnlyInVipMode)
              .map(item => {
                const onClick = () => {
                  if(item.path) {
                    history.push(item.path)
                  }else{
                    if(item.onClick) item.onClick(history)
                  }
                }
                return(
                  <Menu.Item key={item.key} icon={item.icon} onClick={onClick}>
                    {item.text}
                  </Menu.Item>
                )
            })
          }
        </Menu>
      </Sider>
      <Layout style={{ backgroundColor: '#e3e3e3' }}>
        <Content>
          <ContentContainer>
            {children}
          </ContentContainer>
        </Content>
        <Footer>
          <FooterWithVIPmode onVipModeEnabled={onVipModeEnabled} />
        </Footer>
      </Layout>
    </Layout>
  )
}

export default DashboardLayout
