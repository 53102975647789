import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

const Container = styled.div`
  position: relative;
  height: 80px;
  margin: 8px 0;
  background-color: ${props => props.color || 'transparent'};
`

const Button = styled.div`
  color: ${props => props.active ? '#fff' : 'rgba(255, 255, 255, 0.3)'};
  font-size: 48px;
  cursor: ${props => props.active ? 'pointer' : 'inherit'};
  position: absolute;
  top: 50%;
  left: ${props => props.rightSide ? 'inherit' : '30px'};
  right: ${props => props.rightSide ? '30px' : 'inherit'};
  transform: translateY(-50%);
`

const PointsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const PointCircle = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px #fff solid;
  border-radius: 50%;
  margin: 5px;
  background-color: ${props => props.filled ? 'rgba(255, 255, 255, 255)' : 'rgba(255, 255, 255, 0)'};
  transition: .5s;
`

const BestOfMatchCounter = ({ color, value, bestOfValue, onChange }) => {
  const downActive = useMemo(() => value > 0, [value])
  const upActive = useMemo(() => (bestOfValue + 1) / 2 > value, [value, bestOfValue])

  const onDownClick = useCallback(() => {
    if(downActive) onChange(value - 1)
  }, [onChange, value, downActive])

  const onUpClick = useCallback(() => {
    if(upActive) onChange(value + 1)
  }, [onChange, value, upActive])

  return(
    <Container color={color}>
      <Button onClick={onDownClick} active={downActive}>
        <MinusCircleOutlined />
      </Button>
      <PointsContainer>
        {
          [...Array((bestOfValue + 1) / 2).keys()].map(n => (
            <PointCircle key={n} filled={n < value} />
          ))
        }
      </PointsContainer>
      <Button onClick={onUpClick} active={upActive} rightSide>
        <PlusCircleOutlined />
      </Button>
    </Container>
  )
}

BestOfMatchCounter.propTypes = {
  color: PropTypes.string,
  value: PropTypes.number.isRequired,
  bestOfValue: PropTypes.number.isRequired,
  onChange: PropTypes.func,
}

export default BestOfMatchCounter
