import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Button, message, Space} from 'antd'
import {CopyOutlined, LineChartOutlined} from '@ant-design/icons'
import copy from 'copy-to-clipboard'

const ParamContainer = styled.div`
  position: relative;
  background-color: ${props => props.highlighted ? '#68d472' : '#fff'};
  border-bottom: 2px #e3e3e3 solid;
  padding: 20px;
  transition: .4s;
`

const Title = styled.div`
  font-size: 12px;
  color: #5e5e5e;
`

const Value = styled.div`
  font-size: 28px;
`

const ActionButtons = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
`

const Param = ({ title, value, trackerUrl }) => {
  const [highlighted, setHighlighted] = useState(false)

  useEffect(() => {
    if(highlighted) setTimeout(() => setHighlighted(false), 1000)
  }, [highlighted])

  const copyValue = useCallback(() => {
    copy(value)
    setHighlighted(true)
    return message.info('Skopiowano do schowka!')
  }, [value])

  return(
    <ParamContainer highlighted={highlighted}>
      <Value>{value ? value : <i>(brak)</i>}</Value>
      <Title>{title}</Title>
      <ActionButtons>
        <Space size={'middle'}>
          {
            trackerUrl
            &&
            <Button shape={'circle'} size={'large'} onClick={() => window.open(trackerUrl, '_blank').focus()}>
              <LineChartOutlined />
            </Button>
          }
          {
            value
            &&
            <Button shape={'circle'} size={'large'} onClick={copyValue}>
              <CopyOutlined />
            </Button>
          }
        </Space>
      </ActionButtons>
    </ParamContainer>
  )
}

Param.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  trackerUrl: PropTypes.string,
}

export default Param
