import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {countRecords} from "../../../firebase/firestore/firestore";
import {Button, Space} from "antd";
import {BigText, FooterButtons, PanelBlock, SmallText, Title} from "./CommonElements";

export const RecordCounterPanel = ({ title, description, collectionName, goToPath }) => {
    const [count, setCount] = useState(-1)
    const history = useHistory()

    useEffect(() => {
        if(count < 0) setTimeout(() => countRecords(collectionName).then(size => setCount(size)), 500)
    }, [collectionName, count])

    return(
        <PanelBlock>
            <Title>{title}</Title>
            {count >= 0 && <BigText>{count}</BigText>}
            <SmallText>{count < 0 ? 'Pobieranie danych...' : description}</SmallText>
            <FooterButtons>
                <Space>
                    <Button size={'small'} disabled={count < 0} onClick={() => setCount(-1)}>
                        Odśwież
                    </Button>
                    <Button size={'small'} type={'primary'} onClick={() => history.push(goToPath)}>
                        Otwórz
                    </Button>
                </Space>
            </FooterButtons>
        </PanelBlock>
    )
}