import React from 'react'
import FirestoreTableBridge from '../../components/FirestoreTableBridge/FirestoreTableBridge'
import {timestampToString} from '../../utils/dateConverter'
import {message, Popconfirm, Button, Tooltip, Dropdown, Menu, Tag} from 'antd'
import {
  acceptEntry,
  addNewEntry,
  updateEntry,
  updateEntryFlag
} from '../../firebase/firestore/entriesAndTeams.firestore'
import {UsergroupAddOutlined, WarningOutlined} from '@ant-design/icons'
import entryFormFields from '../../config/entryFormFields'
import {divisions, getDivisionByRank, SmallDivisionTag} from '../../utils/rankDivisions'
import {entryFlags} from '../../config/entryFlags'

const PlayerWithTracker = ({v, tracker}) => (
  v
    ?
    <>
      {v}<br />
      <Tooltip placement={'bottom'} title={tracker}>
        <a href={tracker} target={'_blank'} rel="noreferrer">Tracker</a>
      </Tooltip>
    </>
    :
    <i>(brak)</i>
)

const columns = [
  {
    title: 'Zgłoszono',
    dataIndex: 'creationDate',
    render: (v, { flag }) => {
      if(flag) {
        return(
          <>
            {timestampToString(v)}
            <br />
            <Tag color={'#c08841'}>{entryFlags.find(f => f.key === flag).text}</Tag>
          </>
        )
      }
      return timestampToString(v)
    },
  },
  {
    title: 'Gracz nr 1',
    dataIndex: 'player1',
    render: (v, record) => <PlayerWithTracker v={v} tracker={record.tracker1} />,
  },
  {
    title: 'Gracz nr 2',
    dataIndex: 'player2',
    render: (v, record) => <PlayerWithTracker v={v} tracker={record.tracker2} />,
  },
  {
    title: 'Rezerwowy',
    dataIndex: 'reservePlayer',
    render: (v, record) => <PlayerWithTracker v={v} tracker={record.reserveTracker} />,
  },
  {
    title: 'Nazwa drużyny',
    dataIndex: 'teamName',
    render: v => v,
  },
  {
    title: 'Discord',
    dataIndex: 'discordNick',
    render: v => v || <i>(brak)</i>,
  },
  {
    title: 'Ranga',
    dataIndex: 'highestRank',
    render: v => {
      const division = getDivisionByRank(v)
      if (!division) return v
      const color = divisions.find(d => d.name === division).color
      return <>{v}<br/><SmallDivisionTag color={color}>Dywizja: {division}</SmallDivisionTag></>
    },
  },
]

const updateFlagOnClick = (idx, recordData, replaceRecord, flagValue) => {
  updateEntryFlag(recordData.key, flagValue)
    .then(() => {
      replaceRecord(idx, {
        ...recordData,
        flag: flagValue,
      })
      return message.success('Flaga została zaktualizowana!')
    })
}

const flagDropdownMenu = (idx, recordData, replaceRecord) => (
  <Menu>
    <Menu.Item key={'removeFlag'} onClick={() => updateFlagOnClick(idx, recordData, replaceRecord, null)}>Usuń oznaczenie</Menu.Item>
    <Menu.Divider />
    {
      entryFlags.map(({ key, text }) => (
        <Menu.Item key={key} onClick={() => updateFlagOnClick(idx, recordData, replaceRecord, key)}>{text}</Menu.Item>
      ))
    }
  </Menu>
)

const tableConfig = {
  sortOption: [
    {
      key: 'teamName',
      name: 'Nazwa drużyny',
      ascDirection: true,
      descDirection: true,
      extraFields: [],
    },
  ],
  newItemButton: true,
  actions: {
    editItemButton: true,
    deleteItemButton: true,
    custom: (idx, recordData, deleteItem, replaceRecord) => ([
      <Popconfirm
        key={'accept'}
        title={'Czy chcesz zaakceptować drużynę?'}
        onConfirm={() => {
          acceptEntry(recordData.key, recordData)
            .then(() => {
              deleteItem(idx)
              return message.success('Drużyna została zaakceptowana!')
            })
        }}
        okText={'Dodaj do turnieju'}
        cancelText={'Anuluj'}
      >
        <Button size={'large'} icon={<UsergroupAddOutlined />} type={'primary'}>
          Zaakceptuj
        </Button>
      </Popconfirm>,
      <Dropdown trigger={['click']} overlay={flagDropdownMenu(idx, recordData, replaceRecord)}>
        <Button size={'large'} icon={<WarningOutlined />} />
      </Dropdown>,
    ]),
  },
  onNewItemFormSubmit: (data, accept) => {
    addNewEntry(data)
      .then(() => {
        accept()
        return message.success('Dodano nowy zapis')
      })
      .catch(() => message.error('Błąd aktualizacji!'))
  },
  onEditItemFormSubmit: (data, key, accept) => {
    updateEntry(key, data)
      .then(() => {
        accept()
        return message.success('Zapis został zaktualizowany')
      })
      .catch(() => message.error('Błąd aktualizacji!'))
  },
  formFields: entryFormFields,
}

const DashboardEntriesView = () => (
  <>
    <FirestoreTableBridge
      dataSourcePath={`entries`}
      defaultSortMethod={{ key: 'creationDate', direction: 'asc' }}
      columns={columns}
      tableConfig={tableConfig}
      itemsPerPage={false}
    />
  </>
)

export default DashboardEntriesView
