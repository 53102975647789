import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  //background-color: #21242e;
  width: 100vw;
  height: 100vh;
  font-size: 1rem;
  color: #fff;
`

const WidgetLayout = ({ children }) => (
  <Container>
    {children}
  </Container>
)

export default WidgetLayout
