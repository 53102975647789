import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {BOX_STATE} from '../../firebase/firestore/mysteryBox.firestore'
import {Table, Tag} from 'antd'
import {getRecord} from '../../firebase/firestore/firestore'
import {timestampToString} from '../../utils/dateConverter'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`

const columns = [
  {
    title: 'Nr',
    dataIndex: 'no',
    render: v => v,
  },
  {
    title: 'Lokalizacja',
    dataIndex: 'box',
    render: v => v ? <>Box nr {v}</> : <i>(nieznana)</i>,
  },
  {
    title: 'Status',
    dataIndex: 'state',
    render: (v, { modificationDate }) => {
      switch (v) {
        case BOX_STATE.OPEN:
          return <Tag color={'red'}>Otwarty dnia {timestampToString(modificationDate, true, false)}</Tag>
        case BOX_STATE.CLOSE:
          return <Tag color={'geekblue'}>Zamknięty</Tag>
        default:
          return <Tag color={'gray'}>{v}</Tag>
      }
    },
  },
  {
    title: 'Zwycięzca',
    dataIndex: 'receiver',
    render: v => (v ? v : <i>-</i>),
  },
  {
    title: 'Zawartość',
    dataIndex: 'value',
    render: v => v,
  },
]

const MysteryBoxWidget = () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    getRecord('config', 'publicMysteryBoxList')
      .then(doc => {
        const docData = doc.data()
        if(docData.list) {
          docData.list.sort((a, b) => a.no - b.no)
        }
        setData(docData)
      })
  }, [])

  if (!data || !data.list) {
    return(
      <Container>
        <i>Lista jest pusta</i>
      </Container>
    )
  }

  return(
    <Container>
      <Table
        dataSource={data.list}
        columns={columns}
        pagination={false}
      />
    </Container>
  )
}

export default MysteryBoxWidget
