import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCCl9tSR-2l389nJzqCZVdl-v2LzgU3t64',
  authDomain: 'hamcupsystem.firebaseapp.com',
  projectId: 'hamcupsystem',
  storageBucket: 'hamcupsystem.appspot.com',
  messagingSenderId: '349222118184',
  appId: '1:349222118184:web:23d1a88c76fd711d79a61d'
}

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig)

export default firebase
