import React, {useCallback, useEffect, useState} from "react";
import {getEntryFormState, setEntryFormState} from "../../../firebase/firestore/config.firestore";
import {LockOutlined, UnlockOutlined} from "@ant-design/icons";
import {Button, Space} from 'antd'
import {BigText, FooterButtons, PanelBlock, SmallText, Title} from "./CommonElements";
import {Link} from 'react-router-dom'
import {HAMCUP_ENTRY_FORM_URL} from '../../../config/routes'

export const EntryFormPanel = () => {
    const [isOpen, setIsOpen] = useState(-1)
    useEffect(() => {
        getEntryFormState().then(state => setIsOpen(state ? 1 : 0))
    }, [])
    const onClick = useCallback(() => {
        setEntryFormState(isOpen === 0)
        setIsOpen(-1)
        setTimeout(() => getEntryFormState().then(state => setIsOpen(state ? 1 : 0)), 500)
    }, [isOpen])
    if(isOpen < 0) {
        return(
            <PanelBlock>
                <Title>Formularz rejestracji</Title>
                <SmallText>Pobieranie danych...</SmallText>
            </PanelBlock>
        )
    }
  return (
    <PanelBlock>
      <Title>Formularz rejestracji</Title>
      <BigText color={isOpen === 0 ? '#be1414' : '#159f15'}>{isOpen === 0 ? <LockOutlined/> :
        <UnlockOutlined/>}</BigText>
      <FooterButtons>
        <Space>
          <Button size={'small'} danger={isOpen === 1} onClick={onClick}>
            {isOpen === 0 ? 'Odblokuj' : 'Zablokuj'}
          </Button>
          <Link key={'open'} to={HAMCUP_ENTRY_FORM_URL} target="_blank" rel="noopener noreferrer">
            <Button size={'small'} type={'primary'}>
              Otwórz
            </Button>
          </Link>
        </Space>
      </FooterButtons>
    </PanelBlock>
  )
}
