import DashboardLayout from '../layouts/DashboardLayout'
import SimplePageLayout from '../layouts/SimplePageLayout'
import WidgetLayout from '../layouts/WidgetLayout'

import MainPageView from '../views/MainPage/MainPageView'
import SignInView from '../views/SignIn/SignInView'
import DashboardMysteryBox from '../views/Dashboard/DashboardMysteryBoxView'
import EntryFormView from '../views/EntryForm/EntryFormView'
import DashboardRouter from '../views/Dashboard/DashboardRouter'
import DashboardStartView from '../views/Dashboard/DashboardStartView'
import DashboardEntriesView from '../views/Dashboard/DashboardEntriesView'
import DashboardTeamsView from '../views/Dashboard/DashboardTeamsView'
import DashboardScheduleView from '../views/Dashboard/DashboardScheduleView'
import DashboardMatchPanelView from '../views/Dashboard/DashboardMatchPanelView'
import DashboardWidgetsView from '../views/Dashboard/DashboardWidgetsView'
import TeamsWidget from '../views/Widget/TeamsWidget'
import ScheduleWidget from '../views/Widget/ScheduleWidget'
import MatchWidget from '../views/Widget/MatchWidget'
import MysteryBoxWidget from '../views/Widget/MysteryBoxWidget'
import DashboardDataExportView from '../views/Dashboard/DashboardDataExportView'
import DashboardVipModeView from '../views/Dashboard/DashboardVipModeView'

export const DEFAULT_UNAUTH_URL = '/signin'

export const DEFAULT_AUTH_URL = '/dashboard'

export const HAMCUP_ENTRY_FORM_URL = '/hamcup'

export const routes = [
  {
    restricted: false,
    path: '/',
    exact: true,
    name: 'kycu live',
    layout: SimplePageLayout,
    component: MainPageView
  },
  {
    restricted: false,
    path: HAMCUP_ENTRY_FORM_URL,
    exact: true,
    name: 'Ham Cup',
    layout: SimplePageLayout,
    component: EntryFormView
  },
  {
    restricted: false,
    path: '/signin',
    exact: true,
    name: 'Logowanie',
    layout: SimplePageLayout,
    component: SignInView
  },
  {
    restricted: true,
    path: '/dashboard',
    exact: false,
    name: 'Dashboard',
    layout: DashboardLayout,
    component: DashboardRouter,
  },
  {
    restricted: false,
    path: '/w/teams',
    exact: true,
    name: 'Widget - Teams',
    layout: WidgetLayout,
    component: TeamsWidget,
  },
  {
    restricted: false,
    path: '/w/schedule',
    exact: true,
    name: 'Widget - Schedule',
    layout: WidgetLayout,
    component: ScheduleWidget,
  },
  {
    restricted: false,
    path: '/w/match',
    exact: true,
    name: 'Widget - Match',
    layout: WidgetLayout,
    component: MatchWidget,
  },
  {
    restricted: false,
    path: '/w/mysterybox',
    exact: true,
    name: 'Widget - Mystery Box',
    layout: WidgetLayout,
    component: MysteryBoxWidget,
  },
]

export const dashboardRoutes = [
  {
    path: '/dashboard',
    exact: true,
    name: 'Dashboard',
    component: DashboardStartView
  },
  {
    path: '/dashboard/mysterybox',
    exact: true,
    name: 'Niespodzianki',
    component: DashboardMysteryBox
  },
  {
    path: '/dashboard/entries',
    exact: true,
    name: 'Zapisy',
    component: DashboardEntriesView
  },
  {
    path: '/dashboard/teams',
    exact: true,
    name: 'Drużyny',
    component: DashboardTeamsView
  },
  {
    path: '/dashboard/schedule',
    exact: true,
    name: 'Plan rozgrywek',
    component: DashboardScheduleView
  },
  {
    path: '/dashboard/match-panel',
    exact: true,
    name: 'Panel meczu',
    component: DashboardMatchPanelView
  },
  {
    path: '/dashboard/widgets',
    exact: true,
    name: 'Widgety',
    component: DashboardWidgetsView
  },
  {
    path: '/dashboard/data-export',
    exact: true,
    name: 'Eksport danych',
    component: DashboardDataExportView
  },
  {
    path: '/dashboard/vip',
    exact: true,
    name: 'Tryb VIPa z Krakowa',
    component: DashboardVipModeView
  },
]
