import React from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'
import { routes } from './config/routes'
import SecurityRoute from './SecurityRoute'
import SimplePageLayout from './layouts/SimplePageLayout'
import LoaderLayout from './layouts/LoaderLayout'
import styled from 'styled-components'
import KycuSmuteszekImage from './assets/kycuSmuteszek.png'

const NotFoundImage = styled.img`
  display: block;
  margin: 35px auto 10px;
  width: 64px;
`

const App = () => {

  return(
    <HashRouter>
      <React.Suspense fallback={<LoaderLayout />}>
        <Switch>
          {
            routes.map(route => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => (
                  <SecurityRoute restricted={route.restricted}>
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  </SecurityRoute>
                )}
              />
            ))
          }
          <Route>
            <SimplePageLayout>
              <NotFoundImage src={KycuSmuteszekImage} alt={'smuteszek'} />
              <h1 style={{ textAlign: 'center' }}>404 NOT FOUND</h1>
            </SimplePageLayout>
          </Route>
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

export default App;
