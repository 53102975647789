import {
  addNewRecord,
  updateRecord,
  deleteAllDocumentsInCollection,
  getFullCollection,
  setRecord
} from './firestore'

const MYSTERY_BOX_COL = 'mysteryBox'

export const BOX_STATE = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
}

const generateRandomOrder = length => {
  const array = []
  for(let i = 1; i <= length; i++) array.push(i)

  let currentIndex = array.length
  let randomIndex = array.length

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array;
}

export const updatePublicList = () => {
  return getFullCollection(MYSTERY_BOX_COL)
    .then(privateList => {
      const publicList = privateList.map(privateBoxData => ({
        ...privateBoxData,
        box: privateBoxData.state === BOX_STATE.OPEN ? privateBoxData.box : null
      }))
      return setRecord('config', 'publicMysteryBoxList', {
        list: publicList
      })
    })
}

export const generateNewBoxList = async (list) => {
  await deleteAllDocumentsInCollection(MYSTERY_BOX_COL)
  const randomBoxNumbers = generateRandomOrder(list.length)
  await Promise.all(list.map((item, idx) => (
    addNewRecord(MYSTERY_BOX_COL, {
      value: item,
      receiver: null,
      state: BOX_STATE.CLOSE,
      no: idx + 1,
      box: randomBoxNumbers[idx],
    })
  )))
  return updatePublicList()
}

export const openThisBox = async (boxId, receiverName) => {
  await updateRecord(MYSTERY_BOX_COL, boxId, {
    state: BOX_STATE.OPEN,
    receiver: receiverName,
  })
  return updatePublicList()
}
