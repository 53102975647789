import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {listenCollection} from '../../firebase/firestore/firestore'
import {divisions, getDivisionByRank, SmallDivisionTag} from '../../utils/rankDivisions'
import {Button, Checkbox, message, Popover} from 'antd'
import {PhoneFilled} from '@ant-design/icons'
import { ReactComponent as LogoDiscord } from '../../assets/logo-discord.svg'
import copy from 'copy-to-clipboard'

const Container = styled.div`
  background-color: #21242e;
  min-height: 100vh;
`

const FilterContainer = styled.div`
  label {
    color: #fff;
    margin: 10px;
  }
`

const Title = styled.h1`
  color: #fff;
  font-size: 1.2rem;
  margin-left: 10px;
`

const List = styled.ol`

`

const Team = styled.li`
  margin-bottom: 6px;

  a {
    color: #68D472;
    text-decoration: underline;
    font-weight: bold;
  }
  
  a:hover {
    color: #fff;
  }
`

const DiscordIcon = styled(LogoDiscord)`
  margin: 0 3px;
  fill: #fff;
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
`

// TODO filter C and S rank
const allDivisions = divisions.map(d => d.name).filter(d => (d !== 'C' && d !== 'S'))

const PlayerWithTracker = ({ player, tracker }) => (
  tracker
  ?
    <a href={tracker} target={'_blank'} rel='noreferrer'>{player}</a>
    :
    <>{player}</>
)

const ContactWithLeader = ({ contact }) => {
  if(!contact) return <></>
  const popoverContent = (
    <>
      Nick Discord:<br />
      <b>{contact}</b><br />
      <Button onClick={() => { copy(contact); message.info('Skopiowano do schowka') }} block>
        Skopiuj do schowka
      </Button>
    </>
  )
  return (
    <Popover placement={'top'} title={'Kontakt z leaderem'} content={popoverContent}>
      <DiscordIcon />
    </Popover>
  )
}

const Tag = ({ rank }) => {
  const division = getDivisionByRank(rank)
  if (!division) return null
  const color = divisions.find(d => d.name === division).color
  return <SmallDivisionTag color={color} withMargin>Dywizja {division}</SmallDivisionTag>
}

const TeamsWidget = () => {
  const [data, setData] = useState(null)
  const [selectedDivisions, setSelectedDivisions] = useState(allDivisions)
  const [statistics, setStatistics] = useState({})

  useEffect(() => {
    return listenCollection('teams', d => {
      const stats = {}
      setData(
        d.map(t => {
          const name = getDivisionByRank(t.highestRank)
          if(stats.hasOwnProperty(name)) {
            stats[name] += 1
          }else{
            stats[name] = 1
          }
          return {
            ...t,
            divisionName: name
          }
        })
      )
      setStatistics(stats)
    }, ref => ref.orderBy('creationDate', 'asc'))
  }, [])

  if(!data) return <></>
  if(data.length === 0) return <Container><i>Lista zawodników jest pusta</i></Container>

  return(
    <Container>
      <FilterContainer>
        {
          allDivisions.map(d => (
            <Checkbox
              key={d}
              checked={selectedDivisions.includes(d)}
              onChange={e => {
                if(e.target.checked) {
                  setSelectedDivisions(c => [...c, d])
                }else{
                  setSelectedDivisions(c => c.filter(i => i !== d))
                }
              }}
            >
              Dywizja {d} ({statistics[d] || '0'})
            </Checkbox>
          ))
        }
      </FilterContainer>
      <Title>Lista uczestników:</Title>
      <List>
        {
          data
            .filter(t => (t.divisionName === null || selectedDivisions.includes(t.divisionName)))
            .map(t => (
            <Team key={t.docId}>
              <PlayerWithTracker player={t.player1} tracker={t.tracker1} />
              {', '}
              <PlayerWithTracker player={t.player2} tracker={t.tracker2} />
              {' - '}
              {t.teamName}
              <Tag rank={t.highestRank} />
              <ContactWithLeader contact={t.discordNick} />
            </Team>
          ))
        }
      </List>
    </Container>
  )
}

export default TeamsWidget
