import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {Button, Modal, Form} from 'antd'

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 16 },
}

const tailLayout = {
  wrapperCol: { offset: 7, span: 16 },
}

const ModalRecordEditor = ({ show, editMode, initialValues, onCancel, onFinish, onFormChange, fields }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [show, form, initialValues])

  return(
    <Modal
      title={editMode ? 'Edycja danych' : 'Nowa pozycja'}
      visible={show}
      onCancel={onCancel}
      footer={false}
      getContainer={false}
    >
      <Form
        form={form}
        name={'signup'}
        initialValues={initialValues}
        onFinish={onFinish}
        onValuesChange={change => {
          if(onFormChange) onFormChange(change, form, editMode)
        }}
        {...layout}
      >
        {
          fields.map(field => (
            <Form.Item
              key={field.name}
              label={field.label}
              name={field.name}
              rules={field.rules}
              {...field.formItemProps}
            >
              {field.fieldComponent}
            </Form.Item>
          ))
        }
        <Form.Item {...tailLayout}>
          <Button type={'primary'} htmlType={'submit'} block>
            {editMode ? 'Zapisz zmiany' : 'Utwórz'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

ModalRecordEditor.propTypes = {
  fields: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  initialValues: PropTypes.object,
  onFormChange: PropTypes.func,
}

ModalRecordEditor.defaultProps = {
  editMode: false,
  initialValues: {},
}

export default ModalRecordEditor
