import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {listenDocument} from '../../firebase/firestore/firestore'

const leftColor = '#4776f2'
const rightColor = '#de6f3d'

const subLeftColor = '#0a2f92'
const subRightColor = '#783314'

const TeamContainer = styled.div`
  background-color: ${props => props.rightTeam ? rightColor : leftColor};
  position: fixed;
  top: 0;
  left: ${props => props.rightTeam ? 'inherit' : '0'};
  right: ${props => props.rightTeam ? '0' : 'inherit'};
  width: 400px;
  text-align: center;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
`

const NameContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Play:wght@700&display=swap');
  font-family: 'Play', sans-serif;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  font-size: 1.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 15px;
`

const ScoreContainer = styled.div`
  background-color: ${props => props.rightTeam ? subRightColor : subLeftColor};
  display: flex;
  padding: 10px 15px;
  height: 35px;
  transform: ${props => props.rightTeam ? 'scale(1, -1)' : 'scale(1, 1)'};
`

const ScorePoint = styled.div`
  display: inline-block;
  flex: 1;
  width: 60px;
  height: 15px;
  border: 1px #fff solid;
  margin: 0 5px;
  background-color: ${props => props.filled ? 'rgba(255, 255, 255, 255)' : 'rgba(255, 255, 255, 0.1)'};
  transition: .5s;
`

const TeamPanel = ({ teamName, score, bestOf, rightTeam }) => (
  <TeamContainer rightTeam={rightTeam}>
    <NameContainer>{teamName}</NameContainer>
    {
      bestOf > 1
      &&
      <ScoreContainer rightTeam={rightTeam}>
        {
          rightTeam
          ?
            [...Array((bestOf + 1) / 2).keys()].slice(0).reverse().map(n => <ScorePoint key={n} filled={n < score} />)
            :
            [...Array((bestOf + 1) / 2).keys()].map(n => <ScorePoint key={n} filled={n < score} />)
        }
      </ScoreContainer>
    }
  </TeamContainer>
)

const MatchWidget = () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    return listenDocument('config', 'match', d => setData(d))
  }, [])

  if(!data) return <i>Ładowanie widoku...</i>
  if(!data.enabled) return <></>

  return(
    <>
      <TeamPanel teamName={data.team1} score={data.teamWon1} bestOf={data.bestOf} rightTeam={false} />
      <TeamPanel teamName={data.team2} score={data.teamWon2} bestOf={data.bestOf} rightTeam={true} />
    </>
  )
}

export default MatchWidget
