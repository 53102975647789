import React, {useState, useCallback} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Badge, Space, Button, Dropdown, Menu } from 'antd'
import { useBoolean } from 'ahooks'
import { FilterFilled, SortAscendingOutlined, SearchOutlined, PlusCircleFilled, ReloadOutlined } from '@ant-design/icons'
import FilterModal from './FilterModal'
import ModalRecordEditor from './ModalRecordEditor'
import useEffectWithoutInitialRender from '../../hooks/useEffectWithoutInitialRender'

const Container = styled.div`
  text-align: right;
  margin-bottom: 10px;
  padding-right: 10px;
`

const ButtonText = styled.span`
  @media (max-width: 650px) {
    display: none !important;
  }
`

const TableHeader = ({
  applyModifiers,
  search,
  sortOption,
  filterOption,
  newItemButton,
  formFields,
  onNewItemFormSubmit,
  reloadData,
  onFormChange,
  newFormInitialValues
}) => {
  const [sortMethod, setSortMethod] = useState({})
  const [filters, setFilters] = useState({})
  const [filterModalVisible, {
    setTrue: showFilterModal,
    setFalse: hideFilterModal,
  }] = useBoolean(false)
  const [newItemModalVisible, {
    setTrue: showNewItemModalVisible,
    setFalse: hideNewItemModalVisible,
  }] = useBoolean(false)

  const selectSortMethod = useCallback((key, extraFields = [], desc = false) => {
    if(key === '') {
      setSortMethod({})
    }else{
      setSortMethod({
        key,
        desc,
        extraFields,
      })
    }
  }, [])

  const SortDropdownMenu = useCallback(() => (
    <Menu>
      <Menu.Item
        key={'defaultsort'}
        onClick={() => selectSortMethod('')}
      >
                Domyślne
      </Menu.Item>
      {
        sortOption.map(i => (
          <React.Fragment key={i.key}>
            <Menu.Divider />
            {
              i.ascDirection
                            &&
                            <Menu.Item
                              key={i.key+'asc'}
                              onClick={() => selectSortMethod(i.key, i.extraFields)}
                            >
                              {i.name} (rosnąco)
                            </Menu.Item>
            }
            {
              i.descDirection
                            &&
                            <Menu.Item
                              key={i.key+'desc'}
                              onClick={() => selectSortMethod(i.key, i.extraFields, true)}
                            >
                              {i.name} (malejąco)
                            </Menu.Item>
            }
          </React.Fragment>
        ))
      }
    </Menu>
  ), [sortOption, selectSortMethod])

  const selectFilters = useCallback((newFilters) => {
    setFilters(newFilters)
    hideFilterModal()
  }, [hideFilterModal])

  const onNewItemModalFinish = useCallback(data => {
    if(onNewItemFormSubmit) {
      onNewItemFormSubmit(data, () => {
        hideNewItemModalVisible()
        reloadData()
      })
    }
  }, [onNewItemFormSubmit, hideNewItemModalVisible, reloadData])

  useEffectWithoutInitialRender(() => {
    applyModifiers({
      filter: filters,
      sort: sortMethod,
    })
  }, [applyModifiers, sortMethod, filters])

  return(
    <>
      {
        filterOption
        &&
        <FilterModal
          modalVisible={filterModalVisible}
          onSubmit={selectFilters}
          onCancel={hideFilterModal}
          filterConfig={filterOption}
        />
      }
      {
        newItemButton
        &&
        <ModalRecordEditor
          show={newItemModalVisible}
          initialValues={newFormInitialValues}
          onCancel={hideNewItemModalVisible}
          onFinish={onNewItemModalFinish}
          onFormChange={onFormChange}
          fields={formFields}
        />
      }
      <Container>
        <Space wrap>
          {
            newItemButton
                        &&
                        <Button type={'primary'} onClick={showNewItemModalVisible}>
                          <PlusCircleFilled />
                          <ButtonText>Nowy</ButtonText>
                        </Button>
          }
          {
            search
                        &&
                        <Button>
                          <SearchOutlined />
                          <ButtonText>Szukaj</ButtonText>
                        </Button>
          }
          <Button onClick={() => reloadData(false)}>
            <ReloadOutlined />
            <ButtonText>Odśwież</ButtonText>
          </Button>
          {
            sortOption
                        &&
                        <Dropdown overlay={SortDropdownMenu}
                          trigger={['click']}
                          placement={'bottomRight'}
                          arrow
                        >
                          <Badge dot={Object.keys(sortMethod).length > 0} style={{ backgroundColor: '#4c71dd' }}>
                            <Button>
                              <SortAscendingOutlined />
                              <ButtonText>Sortowanie</ButtonText>
                            </Button>
                          </Badge>
                        </Dropdown>
          }
          {
            filterOption
                        &&
                        <Badge count={Object.keys(filters).length} style={{ backgroundColor: '#4c71dd' }}>
                          <Button onClick={showFilterModal}>
                            <FilterFilled />
                            <ButtonText>Filtry</ButtonText>
                          </Button>
                        </Badge>
          }
        </Space>
      </Container>
    </>
  )
}

TableHeader.propTypes = {
  applyModifiers: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  filterOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  formFields: PropTypes.array,
  newItemButton: PropTypes.bool,
  search: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  sortOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  onFormChange: PropTypes.func,
  onNewItemFormSubmit: PropTypes.func,
}

TableHeader.defaultProps = {
  search: false,
  sortOption: false,
  filterOption: false,
  newItemButton: false,
  formFields: [],
}

export default TableHeader
