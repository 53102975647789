import firebase from '../firebase/firebase'
import {
  HomeOutlined,
  DropboxOutlined,
  CheckSquareOutlined,
  TeamOutlined,
  CalendarOutlined,
  PlayCircleOutlined,
  SettingOutlined,
  LogoutOutlined,
  FileZipOutlined,
  StarOutlined,
} from '@ant-design/icons'

export const sideMenu = [
  { key: 'start', text: 'Start', path: '/dashboard', icon: <HomeOutlined /> },
  { key: 'mysterybox', text: 'Mystery box', path: '/dashboard/mysterybox', icon: <DropboxOutlined /> },
  { key: 'entries', text: 'Zapisy', path: '/dashboard/entries', icon: <CheckSquareOutlined /> },
  { key: 'teams', text: 'Drużyny', path: '/dashboard/teams', icon: <TeamOutlined /> },
  { key: 'schedule', text: 'Plan rozgrywek', path: '/dashboard/schedule', icon: <CalendarOutlined /> },
  { key: 'matchPanel', text: 'Panel meczu', path: '/dashboard/match-panel', icon: <PlayCircleOutlined /> },
  { key: 'widgets', text: 'Widgety', path: '/dashboard/widgets', icon: <SettingOutlined /> },
  { key: 'dataexport', text: 'Eksport danych', path: '/dashboard/data-export', icon: <FileZipOutlined /> },
  { key: 'vipMode', text: 'Tryb VIPa z Krakowa', path: '/dashboard/vip', icon: <StarOutlined />, visibleOnlyInVipMode: true },
  { key: 'logout', text: 'Wyloguj', path: null, icon: <LogoutOutlined />, onClick: () => firebase.auth().signOut() },
]
