import firebase from '../firebase'
import removeUndefinedFields from '../../utils/removeUndefinedFields'

export const setRecord = (collectionName, documentName, data) => {
  data = removeUndefinedFields(data)
  return firebase
    .firestore()
    .collection(collectionName)
    .doc(documentName)
    .set({
      ...data,
      creationDate: firebase.firestore.Timestamp.now(),
      modificationDate: firebase.firestore.Timestamp.now()
    })
}

export const addNewRecord = (collectionName, data) => {
  data = removeUndefinedFields(data)
  return firebase
    .firestore()
    .collection(collectionName)
    .add({
      ...data,
      creationDate: firebase.firestore.Timestamp.now(),
      modificationDate: firebase.firestore.Timestamp.now()
    })
}

export const updateRecord = (collectionName, documentId, data) => {
  data = removeUndefinedFields(data)
  return firebase
    .firestore()
    .collection(collectionName)
    .doc(documentId)
    .update({
      ...data,
      modificationDate: firebase.firestore.Timestamp.now()
    })
}

export const deleteRecord = (collectionName, documentId) => {
  return firebase
    .firestore()
    .collection(collectionName)
    .doc(documentId)
    .delete()
}

export const deleteAllDocumentsInCollection = collectionName => {
  return firebase
    .firestore()
    .collection(collectionName)
    .get()
    .then(snap => snap.forEach(doc => doc.ref.delete()))
}

export const getFullCollection = collectionName => {
  return firebase
    .firestore()
    .collection(collectionName)
    .get()
    .then(snap => {
      const docs = []
      snap.forEach(doc => {
        docs.push({
          ...doc.data(),
          docId: doc.id,
        })
      })
      return docs
    })
}

export const getRecord = (collectionName, documentId) => {
  return firebase
    .firestore()
    .collection(collectionName)
    .doc(documentId)
    .get()
}

export const countRecords = collectionName => {
  return firebase
    .firestore()
    .collection(collectionName)
    .get()
    .then(snap => snap.size)
}

export const listenCollection = (collectionName, dataUpdater, docFilterBuilder = null) => {
  let colRef = firebase.firestore().collection(collectionName)
  if(docFilterBuilder) colRef = docFilterBuilder(colRef)
  return colRef.onSnapshot(snap => {
      const docs = []
      snap.forEach(doc => {
        docs.push({
          ...doc.data(),
          docId: doc.id,
        })
      })
      dataUpdater(docs)
    }, () => {
    dataUpdater(null)
  })
}

export const listenDocument = (collectionName, documentName, dataUpdater) => {
  const docRef = firebase.firestore().collection(collectionName).doc(documentName)
  return docRef.onSnapshot(snap => {
    dataUpdater(snap.data())
  }, () => {
    dataUpdater(null)
  })
}
