import firebase from '../firebase'
import rocketLeagueRanks from '../../utils/rocketLeagueRanks'
import {divisions, getDivisionByRank} from '../../utils/rankDivisions'

const getRankColor = rankName => {
  switch (rankName) {
    case rocketLeagueRanks.bronze: return '#b27b2e'
    case rocketLeagueRanks.silver: return '#e1dedf'
    case rocketLeagueRanks.gold: return '#cfa82b'
    case rocketLeagueRanks.platinum: return '#7fc6e5'
    case rocketLeagueRanks.diamond: return '#176eda'
    case rocketLeagueRanks.champion: return '#b496e2'
    case rocketLeagueRanks.grandChampion: return '#c8565d'
    case rocketLeagueRanks.supersonicLegend: return '#b235b1'
    default: return '#7a837a'
  }
}

const getDivisionColor = divisionName => {
  const division = divisions.find(d => d.name === divisionName)
  if (division) return division.color
  return '#7a837a'
}

export const analyzeTeamRanks = () => {
  return firebase
    .firestore()
    .collection('teams')
    .get()
    .then(snap => {
      const stats = []
      snap.docs.forEach(doc => {
        const rank = doc.data().highestRank || ''
        const el = stats.find(i => i.title === rank)
        if(el) {
          el.value += 1
        }else{
          stats.push({
            title: rank,
            value: 1,
          })
        }
      })
      stats.forEach(i => {
        i.color = getRankColor(i.title)
      })
      return stats
    })
}

export const analyzeRankDivisions = () => {
  return firebase
    .firestore()
    .collection('teams')
    .get()
    .then(snap => {
      const stats = []
      snap.docs.forEach(doc => {
        const rank = doc.data().highestRank || ''
        const division = getDivisionByRank(rank) || 'Brak'
        const el = stats.find(i => i.title === division)
        if(el) {
          el.value += 1
        }else{
          stats.push({
            title: division,
            value: 1,
          })
        }
      })
      stats.forEach(i => {
        i.color = getDivisionColor(i.title)
      })
      return stats
    })
}
