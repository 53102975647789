import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import {
  closeActiveMatch,
  defaultMatchState,
  listenMatchDocument,
  updateActiveMatch
} from '../../firebase/firestore/match.firestore'
import {Alert, Button, message, Popconfirm} from 'antd'
import {timestampToString} from '../../utils/dateConverter'
import BestOfMatchCounter from '../../components/BestOfMatchCounter/BestOfMatchCounter'
import TeamInformation from '../../components/TeamInformation/TeamInformation'
import {useHistory} from 'react-router-dom'

const leftColor = '#4776f2'
const rightColor = '#de6f3d'

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15px 0;
`

const Column = styled.div`
  flex: 1;
`

const TeamName = styled.div`
  background-color: ${props => props.color};
  text-align: center;
  color: #fff;
  padding: 35px 5px;
  font-size: 32px;
  font-weight: bold;
`

const DashboardMatchPanelView = () => {
  const [matchData, setMatchData] = useState(defaultMatchState)
  const history = useHistory()

  useEffect(() => listenMatchDocument(state => {
    setMatchData(state)
  }), [])

  const closeMatch = useCallback(() => {
    closeActiveMatch().then(() => {
      history.push('/dashboard/schedule')
      return message.success('Mecz został zakończony!')
    })
  }, [history])

  const sendWonCountUpdate = useCallback((rightTeam, value) => {
    message.info(`Punktacja została zmieniona na ${value}`)
    if(rightTeam) {
      return updateActiveMatch({ teamWon2: value })
    }else{
      return updateActiveMatch({ teamWon1: value })
    }
  }, [])

  if(!matchData.enabled) {
    return (
      <Alert showIcon type={'info'} message={'Brak aktywnego meczu. Wybierz mecz z menu planu rozgrywek.'} />
    )
  }

  return(
    <>
      <Alert
        message={'Widget meczu aktywny'}
        description={`${timestampToString(matchData.time)} - ${matchData.team1} VS ${matchData.team2} (best of ${matchData.bestOf})`}
        type='info'
        action={
          <Popconfirm
            key={'matchend'}
            title={'Czy na pewno chcesz zakończyć?'}
            onConfirm={closeMatch}
            okText={'Zakończ'}
            cancelText={'Nie'}
          >
            <Button danger>Zakończ mecz</Button>
          </Popconfirm>
        }
      />
      <ColumnContainer>
        <Column>
          <TeamName color={leftColor}>{matchData.team1}</TeamName>
          {
            matchData.bestOf > 1
            &&
            <BestOfMatchCounter
              color={leftColor}
              bestOfValue={matchData.bestOf}
              value={matchData.teamWon1}
              onChange={v => sendWonCountUpdate(false, v)}
            />
          }
          <TeamInformation teamId={matchData.teamId1} />
        </Column>
        <Column>
          <TeamName color={rightColor}>{matchData.team2}</TeamName>
          {
            matchData.bestOf > 1
            &&
            <BestOfMatchCounter
              color={rightColor}
              bestOfValue={matchData.bestOf}
              value={matchData.teamWon2}
              onChange={v => sendWonCountUpdate(true, v)}
            />
          }
          <TeamInformation teamId={matchData.teamId2} />
        </Column>
      </ColumnContainer>
    </>
  )
}

export default DashboardMatchPanelView
