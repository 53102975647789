import styled from 'styled-components'
import {PieChart} from "react-minimal-pie-chart";

export const PanelBlock = styled.div`
  position: relative;
  flex: 1;
  background-color: #fff;
  margin: 8px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 4px 3px 3px hsl(0deg 0% 0% / 0.38);
  min-width: 300px;
  max-width: 300px;
  min-height: 250px;
`

export const Title = styled.h1`
  text-align: center;
  font-size: 1rem;
`

export const BigText = styled.h2`
  text-align: center;
  font-size: 5rem;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${props => props.color || '#000'}
`

export const SmallText = styled.h2`
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
  bottom: 60px;
  left: 50%;
  color: #626262;
  transform: translateX(-50%);
`

export const FooterButtons = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
`

export const ChartContainer = styled(PieChart)`
  max-width: 90%;
  display: block;
  margin: 0 auto;
`